import React from "react"
import Helmet from 'react-helmet';
import Layout from "../components/layout"
import { Link } from "gatsby";

const thankYou = () => {
  return (
    <Layout>
      <Helmet>
        <title>Form submitted</title>
      </Helmet>
      <div style={{textAlign: "center", padding:"5vh 0", lineHeight: "1.5"}}>
        <h2>Thanks for reaching out! I will be contacting you soon.</h2>
        <p>Lets go back to <Link to="/">home page</Link></p>
      </div>
    </Layout>
  )
}

export default thankYou;
